import { gql } from "@apollo/client";
import { ReferralDetailsChecklistItem_UserFragment } from "@generated/graphql";
import { clsx } from "clsx";
import { ChecklistItem } from "components/shared";
import { useState } from "react";
import { itemContentStyle } from "sections/Profile/constants";
import { UserReferralSourceModal } from "../../UserReferralSourceCard/components/UserReferralSourceModal";

ReferralDetailsChecklistItem.fragments = {
  user: gql`
    fragment ReferralDetailsChecklistItem_User on User {
      id
      referralName
      referralSources
      otherReferralSource
    }
  `,
};

type Props = {
  user: ReferralDetailsChecklistItem_UserFragment;
  refetch?: () => void;
};

export function ReferralDetailsChecklistItem({ user, refetch }: Props) {
  const { referralSources } = user;
  const [showModal, setShowModal] = useState(false);
  const hasReferralSources = (referralSources ?? []).length > 0;

  return (
    <>
      <ChecklistItem
        loading={!user}
        trackedForCompletion
        complete={hasReferralSources}
        title={
          <div className="flex items-center">
            {`Complete "`}
            <span
              className={clsx(
                "underline-offset-2 decoration-gray-400/90 cursor-pointer",
                "hover:decoration-blue-400",
                !hasReferralSources && "underline "
              )}
              onClick={() => setShowModal(true)}
            >
              How did you hear about TbT?
            </span>
            {`"`}
          </div>
        }
        collapsibleContent={
          !hasReferralSources && (
            <p className={itemContentStyle}>
              To complete this item, fill out your referral sources.
            </p>
          )
        }
      />

      {user && (
        <UserReferralSourceModal
          user={user}
          show={showModal}
          closeModal={() => {
            refetch?.();
            setShowModal(false);
          }}
        />
      )}
    </>
  );
}
