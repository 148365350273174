import { gql } from "@apollo/client";
import {
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
} from "@generated/graphql";
import { getCohortSubjectText } from "@utils/labels";
import { EventScheduleStatus } from "types/events";
import { SelectMenuOption } from "../Inputs/SelectMenu";
import { SubjectOption } from "./types";

export const NO_MATCH = "No match";

export const NO_MATCH_OPTION: SelectMenuOption = {
  id: "NONE",
  value: NO_MATCH,
};

export const statusButtonOptions: CohortSessionStudentAttendanceStatus[] = [
  CohortSessionStudentAttendanceStatus.Present,
  CohortSessionStudentAttendanceStatus.Partial,
  CohortSessionStudentAttendanceStatus.Absent,
];

export const TT_DASH_SORT_ORDER = [
  EventScheduleStatus.ONGOING,
  EventScheduleStatus.UPCOMING,
  EventScheduleStatus.CONCLUDED,
];

export const GET_STUDENT_LIVE_PARTICIPANT_MATCHES = gql`
  query CohortSessionStudentAttendanceMatches($cohortSessionId: ID!) {
    cohortSessionStudentAttendanceMatches(cohortSessionId: $cohortSessionId) {
      match
      student {
        fullName
        id
      }
      distances {
        distance
        participant
      }
    }
  }
`;

export const cohortSubjectOptions: SubjectOption[] = Object.values(
  CohortAssignmentSubject
).map((subject) => ({
  id: subject,
  value: getCohortSubjectText(subject as CohortAssignmentSubject),
  subject: subject.valueOf() as CohortAssignmentSubject,
}));

export const mathSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.Algebra_1,
  CohortAssignmentSubSubject.Algebra_2,
  CohortAssignmentSubSubject.Geometry,
  CohortAssignmentSubSubject.IntegratedMath_1,
  CohortAssignmentSubSubject.IntegratedMath_2,
  CohortAssignmentSubSubject.IntegratedMath_3,
];

export const scienceSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.Biology,
  CohortAssignmentSubSubject.EarthSpace,
  CohortAssignmentSubSubject.Chemistry,
  CohortAssignmentSubSubject.Physics,
];

export const socialScienceSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.WorldHistory,
  CohortAssignmentSubSubject.AmericanHistory,
  CohortAssignmentSubSubject.Government,
];

export const elaSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.English_9,
  CohortAssignmentSubSubject.English_10,
  CohortAssignmentSubSubject.English_11,
  CohortAssignmentSubSubject.English_12,
  CohortAssignmentSubSubject.Fls,
  CohortAssignmentSubSubject.Ufli,
];

export const generalSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
];
