import { AccountRole, TutoringPreferencesPosition } from "@generated/graphql";
import { AccountRoleTextMode, getAccountRoleText } from "@utils/labels";
import { AccountAccessRole, UnauthenticatedUser } from "@utils/routes/types";
import clsx from "clsx";
import { Badge } from "components/shared";
import { useAuth } from "contexts/AuthProvider";

export type BadgeAccountRole = AccountAccessRole | TutoringPreferencesPosition;

type Props = {
  accountRole?: BadgeAccountRole;
  className?: string;
  mode?: AccountRoleTextMode;
};

export const AccountRoleBadge = ({
  accountRole,
  className,
  mode = "long",
}: Props) => {
  const { accountRole: currRole } = useAuth();
  const role = accountRole || currRole;
  return (
    <Badge
      className={clsx(accountRoleColors[role], "whitespace-nowrap ", className)}
    >
      {getAccountRoleText(role, mode)}
    </Badge>
  );
};

const accountRoleColors: Record<BadgeAccountRole, string> = {
  [AccountRole.Admin]: "text-fuchsia-900 bg-fuchsia-200",
  [AccountRole.MentorTeacher]: "text-emerald-900 bg-emerald-200",
  [AccountRole.TutorTeacher]: "text-indigo-900 bg-indigo-200",
  [AccountRole.Visitor]: "text-teal-900 bg-teal-200",
  [TutoringPreferencesPosition.TeacherTutor]: "text-indigo-900 bg-indigo-200",
  [TutoringPreferencesPosition.InstructionalSupport]: "text-sky-900 bg-sky-200",
  [UnauthenticatedUser.NonAuthedUser]: "text-slate-900 bg-slate-200",
};
