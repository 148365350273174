import { gql } from "@apollo/client";
import {
  AddEditTeachingExperienceModal_TeachingExperienceFragment,
  User,
} from "@generated/graphql";
import { Modal } from "components/shared";
import { AddEditTeachingExperienceModalBody } from "./AddEditTeachingExperienceModalBody";

AddEditTeachingExperienceModal.fragments = {
  teachingExperience: gql`
    fragment AddEditTeachingExperienceModal_TeachingExperience on TeachingExperience {
      id
      ...AddEditTeachingExperienceModalBody_TeachingExperience
    }
    ${AddEditTeachingExperienceModalBody.fragments.teachingExperience}
  `,
};

type Props = {
  show: boolean;
  userId: User["id"];
  experience:
    | AddEditTeachingExperienceModal_TeachingExperienceFragment
    | null
    | undefined;
  closeModal: () => void;
};

export function AddEditTeachingExperienceModal({
  show,
  userId,
  experience,
  closeModal,
}: Props) {
  return (
    <Modal
      show={show}
      width="medium"
      title="Teaching Experience"
      icon={<Modal.Icon icon="requirements" />}
      subtitle="Let us know your teaching experience."
      onClose={closeModal}
    >
      <AddEditTeachingExperienceModalBody
        userId={userId}
        experience={experience}
        closeModal={closeModal}
      />
    </Modal>
  );
}
