import {
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  GradeLevel,
} from "@generated/graphql";
import {
  CohortAssignmentSubjectTextMode,
  getCohortSubjectText,
  getGradeLevelText,
} from "@utils/labels";
import clsx from "clsx";
import { Icon } from "../Icon";
import { Badge } from "./Badge";

type Props = {
  cohortSubject: CohortAssignmentSubject;
  cohortSubSubject?: CohortAssignmentSubSubject;
  gradeLevels?: GradeLevel[];
  mode?: CohortAssignmentSubjectTextMode;
  className?: string;
};

export const CohortAssignmentSubjectBadge = ({
  cohortSubject,
  cohortSubSubject,
  gradeLevels,
  mode = "long",
  className,
}: Props) => (
  <Badge
    className={clsx(
      SubjectColors[cohortSubject],
      "flex gap-x-0.5 text-center w-auto px-2 py-0.5 rounded-full whitespace-nowrap",
      className
    )}
  >
    {gradeLevels && gradeLevels.length > 0 && (
      <div className="flex flex-row gap-x-0.5 items-center">
        <Icon
          icon="grade"
          className="w-[10px] h-[10px]"
          color={SubjectColors[cohortSubject]}
        />
        <span className="text-xs font-medium leading-4">
          {gradeLevels
            .map((gradeLevel) => getGradeLevelText(gradeLevel))
            .join(", ")}
        </span>
      </div>
    )}
    {getCohortSubjectText(cohortSubject, mode, cohortSubSubject)}
  </Badge>
);

export const SubjectColors: { [key in CohortAssignmentSubject]: string } = {
  [CohortAssignmentSubject.Math]: "text-purple-900 bg-purple-200",
  [CohortAssignmentSubject.Ela]: "text-emerald-900 bg-emerald-200",
  [CohortAssignmentSubject.General]: "text-blue-900 bg-blue-200",
  [CohortAssignmentSubject.Science]: "text-teal-900 bg-teal-200",
  [CohortAssignmentSubject.SocialStudies]: "text-pink-900 bg-pink-200",
  [CohortAssignmentSubject.Eld]: "text-orange-900 bg-orange-200",
};
