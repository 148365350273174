import { gql } from "@apollo/client";
import { GetEventString_CohortEventFragment } from "@generated/graphql";
import { floatingToZonedTimeString } from "@utils/dateTime";
import { getCohortSubjectText, getTimeZoneText } from "@utils/labels";

getEventString.fragments = {
  cohortEvent: gql`
    fragment GetEventString_CohortEvent on CohortEvent {
      startDateTime
      durationMinutes
      timeZone
      recurrenceRule
      cohortSubject
      cohortSubSubject
    }
  `,
};

export function getEventString({
  startDateTime,
  durationMinutes,
  timeZone,
  recurrenceRule,
  cohortSubject,
  cohortSubSubject,
}: GetEventString_CohortEventFragment): string {
  const weekdays = recurrenceRule.match(/BYDAY=([^;]+)/);

  return `${getCohortSubjectText(
    cohortSubject,
    "short",
    cohortSubSubject
  )}-${floatingToZonedTimeString(
    new Date(startDateTime),
    timeZone
  )} ${getTimeZoneText(timeZone, "short")}+${durationMinutes}@${
    weekdays?.[1] ?? ""
  }`;
}
