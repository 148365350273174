import { gql } from "@apollo/client";
import { CohortAssignmentSubject } from "@generated/graphql";
import { getInputStyle } from "@utils/styleStrings";
import { InputNumber, Slider } from "antd";
import clsx from "clsx";
import { cloneDeep, isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { Icon } from "../../Icon";
import { AbsentFromAssessmentCheckbox } from "./components/AbsentFromAssessmentCheckbox";
import {
  GRADE_TYPE_TO_ABSENT_TYPE_MAP,
  absentKeys,
  elaGradeOptions,
  generalGradeOptions,
  gradeTitles,
} from "./constants";
import { GradeType, StudentGrades } from "./types";
import { hasSomeAbsentGrades, hasSomeTruthyGrades } from "./utils";

const EMPTY_GRADE_TEXT = "NA";

GradingPanel.fragments = {
  cohortSessionStudentGrading: gql`
    fragment GradingPanel_CohortSessionStudentGrading on CohortSessionStudentGrading {
      id
      studentId
      readingGrade
      classworkGrade
      languageArtsGrade
      participationGrade
      readingAbsentFromAssessment
      classworkAbsentFromAssessment
      languageArtsAbsentFromAssessment
    }
  `,
  tutorDashboardCohortSessionStudentGrading: gql`
    fragment GradingPanel_StudentGradingRecord on TutorDashboardCohortSessionStudentGrading {
      id
      studentId
      readingGrade
      classworkGrade
      languageArtsGrade
      participationGrade
      readingAbsentFromAssessment
      classworkAbsentFromAssessment
      languageArtsAbsentFromAssessment
    }
  `,
};

type Props<G extends StudentGrades> = {
  studentGrades: G;
  readOnly?: boolean;
  className?: string;
  disableGrading: boolean;
  subject: CohortAssignmentSubject;
  updateStudentGrade: (grades: G) => void;
};

export function GradingPanel<G extends StudentGrades>({
  subject,
  studentGrades,
  className = "",
  disableGrading,
  readOnly = false,
  updateStudentGrade,
}: Props<G>) {
  const [draftGrades, setDraftGrades] = useState<G>(studentGrades);
  const [debouncedDraftGrades] = useDebounce(draftGrades, 700);
  const initialGrades = useRef<G>(studentGrades);

  const isELA = subject === CohortAssignmentSubject.Ela;
  const gradeOptions = isELA ? elaGradeOptions : generalGradeOptions;

  const setDraftGrade = (gradeType: GradeType, grade: number | null | "") => {
    setDraftGrades((prevGrades) => {
      const newGrades = { ...prevGrades };
      newGrades[gradeType] = grade === "" ? null : grade;
      return newGrades;
    });
  };

  const setDraftAbsentFromAssessment = (
    gradeType: GradeType,
    absent: boolean
  ) => {
    const absentType = GRADE_TYPE_TO_ABSENT_TYPE_MAP[gradeType];
    if (!absentType) return;

    setDraftGrades((prevGrades) => {
      const newGrades = cloneDeep(prevGrades);
      if (absent) newGrades[gradeType] = null;
      newGrades[absentType] = absent;
      return newGrades;
    });
  };

  // Reset grades if disableGrading is toggled.
  useEffect(() => {
    if (disableGrading) {
      const gradesCpy = cloneDeep(draftGrades);
      gradeOptions.forEach((gradeType) => (gradesCpy[gradeType] = null));
      absentKeys.forEach((absentType) => (gradesCpy[absentType] = false));
      const hasSomeGrades = hasSomeTruthyGrades(draftGrades, gradeOptions);
      const hasAbsentGrades = hasSomeAbsentGrades(draftGrades, absentKeys);
      if (hasSomeGrades || hasAbsentGrades) {
        setDraftGrades(gradesCpy);
        updateStudentGrade(gradesCpy);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableGrading]);

  // Sync internal grade updates
  useEffect(() => {
    if (!isEqual(debouncedDraftGrades, initialGrades.current)) {
      initialGrades.current = debouncedDraftGrades;
      if (!isEqual(debouncedDraftGrades, studentGrades))
        updateStudentGrade(debouncedDraftGrades);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDraftGrades]);

  // Sync external grade updates
  useEffect(() => {
    if (!isEqual(studentGrades, initialGrades.current)) {
      initialGrades.current = studentGrades;
      setDraftGrades(studentGrades);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentGrades]);

  const renderGradeInputRow = (gradeType: GradeType) => {
    const absentType = GRADE_TYPE_TO_ABSENT_TYPE_MAP[gradeType];
    const isDisabled =
      disableGrading || ((absentType && draftGrades?.[absentType]) ?? false);

    return readOnly ? (
      <div
        key={gradeType}
        className="flex flex-start gap-x-2 text-sm leading-none"
      >
        <div className="font-semibold text-gray-600 min-w-[105px]">
          {gradeTitles[gradeType]}
        </div>
        <p className="text-cyan-800 font-extrabold">
          {studentGrades[gradeType]}
        </p>
      </div>
    ) : (
      <div
        key={gradeType}
        className={clsx("flex w-full items-center", className)}
      >
        <p className="text-sm font-medium text-gray-700 w-[110px]">
          {gradeTitles[gradeType]}
        </p>

        <div className="flex flex-1 flex-grow px-0 mx-0">
          <Slider
            min={-1}
            max={100}
            step={5}
            styles={{
              track: { backgroundColor: "#475569" },
              rail: { backgroundColor: "#e5e7eb" },
            }}
            style={{ width: "95%", margin: "0 4px" }}
            tooltip={{
              formatter: (value) =>
                !value || value === -1
                  ? EMPTY_GRADE_TEXT
                  : value === 100
                  ? value
                  : value + 1,
            }}
            onChange={(value) => {
              const grade =
                value === -1 ? -1 : value === 100 ? value : value + 1;
              setDraftGrade(gradeType, grade);
            }}
            value={draftGrades[gradeType] ?? -1}
            disabled={
              disableGrading ||
              ((absentType && draftGrades?.[absentType]) ?? false)
            }
          />
        </div>

        <div className="flex flex-row flex-nowrap gap-x-[6px] w-[102px] items-center">
          <InputNumber
            min={-1}
            max={100}
            style={{
              ...getInputStyle,
              width: "70px",
              height: "26px",
              borderRadius: "4px",
            }}
            value={
              (draftGrades?.[gradeType] ?? -1) < 0
                ? EMPTY_GRADE_TEXT
                : draftGrades[gradeType]
            }
            controls={
              isDisabled
                ? false
                : {
                    upIcon: <Icon icon="sortUp" size={3} />,
                    downIcon: <Icon icon="sortDown" size={3} />,
                  }
            }
            onStep={(value) =>
              setDraftGrade(
                gradeType,
                value === EMPTY_GRADE_TEXT ? null : value
              )
            }
            onChange={(value) =>
              setDraftGrade(
                gradeType,
                value === EMPTY_GRADE_TEXT ? null : value
              )
            }
            disabled={isDisabled}
          />
          {absentType && (
            <AbsentFromAssessmentCheckbox
              key={`${gradeType}-${absentType}-checkbox`}
              disabled={disableGrading}
              checked={draftGrades[absentType]}
              onChange={(newState) =>
                setDraftAbsentFromAssessment(gradeType, newState)
              }
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={clsx(
        "flex flex-col justify-start w-full gap-y-[6px]",
        !readOnly && "max-w-[800px] items-end"
      )}
    >
      {gradeOptions.map(renderGradeInputRow)}
    </div>
  );
}
