import { AddEditTeachingExperienceModal_TeachingExperienceFragment } from "@generated/graphql";

type ExperienceData = AddEditTeachingExperienceModal_TeachingExperienceFragment;

export enum CertIndex {
  EslEllCert = 0,
  TeflTesolCert = 1,
  SpecialEdCert = 2,
  BilingualCert = 3,
}

export enum ExperienceIndex {
  ApOrIbXp = 0,
  TestPrepXp = 1,
  BilingualXp = 2,
  ReadinessXp = 3,
}

export enum BoolSetter {
  SetCerts = "SET_CERTS",
  SetExperience = "SET_EXPERIENCE",
}

export type State = {
  certifications: boolean[];
  otherExperience: boolean[];
};

export const initialState: State = {
  certifications: [false, false, false, false],
  otherExperience: [false, false, false, false],
};

// Action types
type Action =
  | { type: BoolSetter.SetCerts; payload: boolean[] }
  | { type: BoolSetter.SetExperience; payload: boolean[] }
  | { type: "LOAD_DATA"; payload: ExperienceData };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case BoolSetter.SetCerts:
      return { ...state, certifications: action.payload };
    case BoolSetter.SetExperience:
      return { ...state, otherExperience: action.payload };
    case "LOAD_DATA":
      return {
        ...state,
        certifications: [
          action.payload?.hasEslOrEllCertification ?? false,
          action.payload?.hasTeflOrTesolCertification ?? false,
          action.payload?.hasSpecialEducationCertification ?? false,
          action.payload?.hasBilingualInstructionCertification ?? false,
        ],
        otherExperience: [
          action.payload?.hasApOrIbExperience ?? false,
          action.payload?.hasSatOrActTestPrepExperience ?? false,
          action.payload?.hasBilingualInstructionExperience ?? false,
          action.payload?.hasCollegeAndCareerReadinessExperience ?? false,
        ],
      };
    default:
      return state;
  }
};
