import {
  EducationDegree,
  GradeLevel,
  InfractionType,
  UserEthnicity,
  UserPronouns,
  UserRacialIdentity,
} from "@generated/graphql";
import { SelectMenuOption } from "components/shared";
import { CountryCode, getTimezonesForCountry } from "countries-and-timezones";
import { getTimeZoneString } from "./geo";
import {
  getEducationDegreeText,
  getGradeLevelText,
  getInfractionTypeText,
  getUserEthnicityText,
  getUserPronounsText,
  getUserRacialIdentityText,
} from "./labels";
import { sortEducationDegrees, sortGradeLevels } from "./sort";

export const gradeLevelOptions: SelectMenuOption<{
  gradeLevel: GradeLevel;
}>[] = [
  {
    id: "NONE",
    value: "--",
    gradeLevel: GradeLevel.Unknown,
  },
  ...Object.values(GradeLevel)
    .map((gradeLevel) => ({
      id: gradeLevel,
      gradeLevel,
      value: getGradeLevelText(gradeLevel),
    }))
    .filter(({ gradeLevel }) => gradeLevel !== GradeLevel.Unknown)
    .sort((a, b) => sortGradeLevels(a.gradeLevel, b.gradeLevel)),
] as const;

export const userRacialIdentityOptions: SelectMenuOption<{
  racialIdentity: UserRacialIdentity | null;
}>[] = [
  {
    id: "NONE",
    value: "--",
    racialIdentity: null,
  },
  ...Object.values(UserRacialIdentity)
    .sort((a, b) => {
      if (a === UserRacialIdentity.PreferNotToSay) return 1;
      if (b === UserRacialIdentity.PreferNotToSay) return -1;
      if (a === UserRacialIdentity.IdentityNotListed) return 1;
      if (b === UserRacialIdentity.IdentityNotListed) return -1;
      return getUserRacialIdentityText(a).localeCompare(
        getUserRacialIdentityText(b)
      );
    })
    .map((racialIdentity) => ({
      id: racialIdentity,
      racialIdentity,
      value: getUserRacialIdentityText(racialIdentity),
    })),
] as const;

export const userEthnicityOptions: SelectMenuOption<{
  ethnicity: UserEthnicity | null;
}>[] = [
  {
    id: "NONE",
    ethnicity: null,
    value: "--",
  },
  ...Object.values(UserEthnicity).map((ethnicity) => ({
    id: ethnicity,
    ethnicity,
    value: getUserEthnicityText(ethnicity),
  })),
] as const;

export const userPronounsOptions: SelectMenuOption<{
  pronouns: UserPronouns | null;
}>[] = [
  {
    id: "NONE",
    value: "--",
    pronouns: null,
  },
  ...Object.values(UserPronouns)
    .sort((a, b) =>
      getUserPronounsText(a).localeCompare(getUserPronounsText(b))
    )
    .map((pronouns) => ({
      id: pronouns,
      pronouns,
      value: getUserPronounsText(pronouns, "", false),
    })),
] as const;

export const userEducationDegreeOptions = (): SelectMenuOption<{
  degree: EducationDegree | null;
}>[] =>
  [
    ...Object.values(EducationDegree)
      .sort(sortEducationDegrees)
      .map((degree) => ({
        id: degree,
        degree,
        value: getEducationDegreeText(degree),
      })),
  ] as const;

export const countryTimeZoneOptions = (
  countryCode: CountryCode | string | null | undefined
): SelectMenuOption<{
  timeZone: string | null;
}>[] => [
  {
    id: "none",
    value: "--",
    selectedLabel: "None Selected",
    timeZone: null,
  },
  ...(getTimezonesForCountry(countryCode ?? "")?.map(({ name }) => ({
    id: name,
    value: name,
    selectedLabel: getTimeZoneString(name),
    timeZone: name,
  })) || []),
];

export const infractionTypeOptions: SelectMenuOption<{
  infractionType: InfractionType | null;
}>[] = [
  {
    id: "NONE",
    value: "--",
    infractionType: null,
  },
  ...[
    InfractionType.Attendance,
    InfractionType.Communication,
    InfractionType.Reporting,
    InfractionType.TechIssues,
    InfractionType.Unprepared,
    InfractionType.Unprofessional,
    InfractionType.Other,
  ].map((infractionType) => ({
    id: infractionType,
    value: getInfractionTypeText(infractionType),
    infractionType,
  })),
] as const;
