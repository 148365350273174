import { gql } from "@apollo/client";
import {
  RosterRole,
  TutoringPreferencesCardBody_TutoringPreferencesFragment,
  TutoringPreferencesPosition,
} from "@generated/graphql";
import { IANAtzName } from "@utils/dateTime";
import { getDayOfWeekArrayOrderedText, getGradeLevelText } from "@utils/labels";
import { sortGradeLevels } from "@utils/sort";
import clsx from "clsx";
import {
  CardDetails,
  CohortAssignmentSubjectBadge,
  RosterRoleBadge,
} from "components/shared";
import { HeightMode } from "components/shared/Card";
import { YesOrNoInput } from "components/shared/Inputs";
import { YesNoNullTheme } from "components/shared/Inputs/YesOrNoInput";
import pluralize from "pluralize";
import React, { Fragment } from "react";
import {
  enEsComfortableQuestion,
  esComfortableQuestion,
  esFluentQuestion,
  itemContentStyle,
  itemSubHeaderStyle,
  sectionBodyWrapperStyle,
  sectionWrapperStyle,
} from "../../../constants";
import { EllEldTooltip, TestPrepTooltip } from "../../../helpers";
import { TimeSlotRangeTooltip } from "../../TimeSlotRangeTooltip";

TutoringPreferencesCardBody.fragments = {
  tutoringPreferences: gql`
    fragment TutoringPreferencesCardBody_TutoringPreferences on TutoringPreferences {
      id
      positions
      subjects {
        id
        grades
        subject
      }
      ellEld
      testPrepSubjects
      availabilities {
        daysOfWeek
        timeSlotId
        ...TimeSlotRangeTooltip_TutoringPreferencesAvailability
      }
      esFluent
      esComfortable
      enEsComfortable
      additionalLanguages
    }
    ${TimeSlotRangeTooltip.fragments.tutoringPreferencesAvailability}
  `,
};

type Props = {
  clientTimeZoneOverride?: IANAtzName;
  tutoringPreferences: TutoringPreferencesCardBody_TutoringPreferencesFragment;
};

export function TutoringPreferencesCardBody({
  clientTimeZoneOverride: tzOverride,
  tutoringPreferences: {
    ellEld,
    esFluent,
    subjects,
    positions,
    esComfortable,
    availabilities,
    enEsComfortable,
    testPrepSubjects,
    additionalLanguages,
  },
}: Props) {
  const { MiniMuted } = YesNoNullTheme;
  const { TutorTeacher: Tutor, SubstituteTeacher: Sub } = RosterRole;
  const { InstructionalSupport, TeacherTutor } = TutoringPreferencesPosition;

  const isTT = positions.includes(TeacherTutor);
  const isIS = positions.includes(InstructionalSupport);
  const noPositions = !isTT && !isIS;

  return (
    <>
      <CardDetails
        heightMode={HeightMode.Full}
        rows={[
          [
            pluralize("Teaching Position", positions.length),
            <div key={0} className="flex flex-row gap-x-2 gap-y-1 flex-wrap">
              {isTT && <RosterRoleBadge rosterRole={Tutor} mode="long" />}
              {isIS && <RosterRoleBadge rosterRole={Sub} mode="long" />}
              {noPositions && <span>--</span>}
            </div>,
          ],
          [
            "Availability",
            (availabilities ?? []).filter(
              ({ daysOfWeek }) => (daysOfWeek ?? []).length > 0
            ).length > 0 ? (
              <div
                key={0}
                className="grid grid-cols-[auto_1fr] gap-x-[60px] gap-y-1"
              >
                {availabilities.map((availability, i) => (
                  <React.Fragment key={i}>
                    <TimeSlotRangeTooltip
                      className={itemSubHeaderStyle}
                      clientTimeZoneOverride={tzOverride}
                      tutoringPreferencesAvailability={availability}
                    />
                    <span className={itemContentStyle}>
                      {availability.daysOfWeek
                        ? getDayOfWeekArrayOrderedText(
                            availability.daysOfWeek ?? [],
                            "compact",
                            ", "
                          )
                        : "--"}
                    </span>
                  </React.Fragment>
                ))}
              </div>
            ) : (
              "--"
            ),
          ],
          [
            <TestPrepTooltip inCard key={0} />,
            <div key={0} className="flex gap-x-2">
              {testPrepSubjects.length > 0 ? (
                testPrepSubjects.map((s) => (
                  <CohortAssignmentSubjectBadge key={s} cohortSubject={s} />
                ))
              ) : (
                <YesOrNoInput isYes={false} theme={MiniMuted} readOnly />
              )}
            </div>,
          ],
          [
            "Subjects & Grades",
            (subjects ?? []).length > 0 ? (
              <div
                key={0}
                className="grid grid-cols-[auto_1fr] gap-x-12 gap-y-2"
              >
                {subjects.map(({ subject, grades }) => (
                  <Fragment key={subject}>
                    <CohortAssignmentSubjectBadge cohortSubject={subject} />
                    <span className={itemContentStyle}>
                      {[...(grades ?? [])]
                        .sort(sortGradeLevels)
                        .map(getGradeLevelText)
                        .join(", ")}
                    </span>
                  </Fragment>
                ))}
              </div>
            ) : (
              "--"
            ),
          ],
          [
            <EllEldTooltip inCard key={0} />,
            <YesOrNoInput key={0} isYes={ellEld} theme={MiniMuted} readOnly />,
          ],
        ]}
      />
      <div className={clsx(sectionWrapperStyle, "bg-slate-100 p-3")}>
        <p className="font-semibold text-sm text-slate-600 leading-tight">
          Languages Spoken
        </p>
        <div
          className={clsx(
            sectionBodyWrapperStyle,
            "flex flex-col !items-start gap-y-1.5 !py-[10px] px-3"
          )}
        >
          <div className="flex items-center gap-x-5">
            <span className={itemContentStyle}>{esFluentQuestion}</span>
            <YesOrNoInput readOnly key={0} theme={MiniMuted} isYes={esFluent} />
          </div>
          <div className="flex items-center gap-x-5">
            <span className={itemContentStyle}>{esComfortableQuestion}</span>
            <YesOrNoInput
              key={0}
              readOnly
              theme={MiniMuted}
              isYes={esComfortable}
            />
          </div>
          <div className="flex items-center gap-x-5">
            <span className={itemContentStyle}>{enEsComfortableQuestion}</span>
            <YesOrNoInput
              key={0}
              readOnly
              theme={MiniMuted}
              isYes={enEsComfortable}
            />
          </div>
          <div className="flex gap-x-4 w-full">
            <span className={itemContentStyle}>Additional Languages?</span>

            <span
              key={0}
              className="flex items-center text-[14px] font-semibold text-gray-800 leading-5"
            >
              {additionalLanguages?.trim() || (
                <YesOrNoInput isYes={false} theme={MiniMuted} readOnly />
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
