import clsx from "clsx";
import { Icon } from "../../Icon";

type Props = {
  position: "left" | "right";
  updateNavButtonOffset: (offset: number) => void;
};

export const CalendarNavButton = ({
  position,
  updateNavButtonOffset,
}: Props) => (
  <div
    className={clsx(
      "flex flex-center cursor-pointer group h-[246px] z-[10]",
      "border border-gray-300/70 rounded-lg bg-white hover:bg-slate-50 w-10"
    )}
    onClick={() => updateNavButtonOffset(position === "left" ? -1 : 1)}
  >
    <Icon
      size={10}
      className="-mx-1"
      color="text-slate-600 group-hover:scale-[1.1]"
      icon={position === "left" ? "chevronLeft" : "chevronRight"}
    />
  </div>
);
