import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Icon } from "../Icon";
import { DotsMenu } from "./DotsMenu";

type Props = {
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  children?: React.ReactNode;
  deleteText?: string;
  editText?: string;
  deleteIcon?: React.ReactNode;
  editIcon?: React.ReactNode;
};

export const ContextMenu = ({
  onClickEdit,
  onClickDelete,
  children,
  editText,
  deleteText,
  editIcon,
  deleteIcon,
}: Props) => (
  <DotsMenu>
    <Menu.Items
      className={clsx(
        "absolute z-10 right-0 mt-2 w-56 bg-white rounded-md focus:outline-none",
        "shadow-lg origin-top-right ring-1 ring-black ring-opacity-5 overflow-hidden"
      )}
    >
      <div data-test="context-menu-options">
        {onClickEdit && (
          <Menu.Item>
            {({ active }) => {
              const isDisabled = onClickEdit == null;

              return (
                <button
                  disabled={isDisabled}
                  className={clsx(
                    menuButtonStyles,
                    isDisabled
                      ? "text-gray-300"
                      : active
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-700"
                  )}
                  {...(onClickEdit ? { onClick: onClickEdit } : {})}
                >
                  {editIcon ?? (
                    <Icon
                      icon="edit"
                      size={4}
                      className={clsx(
                        isDisabled
                          ? "text-gray-300"
                          : "text-gray-400 group-hover:text-gray-500"
                      )}
                    />
                  )}
                  {editText ?? "Edit"}
                </button>
              );
            }}
          </Menu.Item>
        )}

        {onClickDelete && (
          <Menu.Item>
            {({ active }) => {
              const isDisabled = onClickDelete == null;

              return (
                <button
                  disabled={isDisabled}
                  className={clsx(
                    menuButtonStyles,
                    isDisabled
                      ? "text-gray-300"
                      : active
                      ? "bg-red-600 text-white"
                      : "text-red-600"
                  )}
                  {...(onClickDelete ? { onClick: onClickDelete } : {})}
                >
                  {deleteIcon ?? (
                    <Icon
                      icon="trash"
                      size={4}
                      className={clsx(
                        isDisabled
                          ? "text-gray-300"
                          : "text-red-600 group-hover:text-white"
                      )}
                    />
                  )}
                  {deleteText ?? "Delete"}
                </button>
              );
            }}
          </Menu.Item>
        )}
        {children}
      </div>
    </Menu.Items>
  </DotsMenu>
);

export const menuButtonStyles =
  "group flex items-center px-4 py-2 w-full text-sm gap-x-3";
