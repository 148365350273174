import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import noop from "lodash/noop";
import { Fragment, useEffect, useState } from "react";
import { Icon } from "../Icon";

/**
 * Utilize this type to define the options that will be available for the Select
 * menu. If you need to add additional properties to the option, you can use the
 * generic syntax, otherwise you can leave it as is.
 */
export type SelectAddButtonOption<O = unknown> = O & {
  id: string;
  value: string;
  disabled?: boolean;
  selectedLabel?: string;
};

type Props<O> = {
  label?: string;
  disabled?: boolean;
  initialIndex?: number;
  outsideOptionSelected?: boolean;
  options: SelectAddButtonOption<O>[];
  onAdd: (option: SelectAddButtonOption<O>) => void;
};

export function SelectAddButton<O>({
  label,
  options,
  disabled = false,
  initialIndex = 0,
  outsideOptionSelected,
  onAdd,
}: Props<O>) {
  const [selected, setSelected] = useState(options[initialIndex] ?? options[0]);

  useEffect(() => {
    setSelected(options[initialIndex] ?? options[0]);
  }, [initialIndex, options]);

  return (
    <div className="flex flex-col gap-1 w-auto">
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="relative z-10 inline-flex shadow-sm rounded-md">
        <button
          type="button"
          className={clsx(
            "w-full px-2 py-2",
            "border rounded-l-md",
            "relative inline-flex flex-center",
            "text-sm font-medium",
            outsideOptionSelected
              ? "bg-blue-500 text-white hover:bg-blue-700 border-blue-500"
              : "bg-white text-gray-700 border-gray-300",
            disabled &&
              "cursor-not-allowed text-gray-500 bg-white hover:bg-white border-gray-300"
          )}
          onClick={selected ? () => onAdd(selected) : noop}
        >
          <div
            className={clsx(
              "flex whitespace-nowrap gap-x-1 items-center",
              disabled && "opacity-50"
            )}
          >
            <Icon
              icon="plus"
              size={3}
              color={clsx(
                outsideOptionSelected ? "text-white" : "text-gray-500"
              )}
            />
            <span className="mr-2">{selected.value}</span>
          </div>
        </button>

        <Menu as="div" className="-ml-px relative block">
          <Menu.Button
            className={clsx(
              "relative inline-flex items-center h-full px-2 py-2 rounded-r-md border text-sm font-medium",
              outsideOptionSelected
                ? "bg-blue-500 text-white hover:bg-blue-700 border-blue-500 border-r border-l-blue-800"
                : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
            )}
          >
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {options.map((option) => (
                  <Menu.Item key={option.id} disabled={option.disabled}>
                    {({ active }) => (
                      <button
                        type="button"
                        className={clsx(
                          option.disabled && "opacity-50",
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "w-full block px-4 py-2 text-sm text-left whitespace-nowrap"
                        )}
                        onClick={() => setSelected(option)}
                      >
                        {option.selectedLabel ?? option.value}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
