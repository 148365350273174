import { gql } from "@apollo/client";
import { Cohort, CohortsBin_CohortBaseFragment } from "@generated/graphql";
import clsx from "clsx";
import { CohortButton } from "./components/CohortButton";

CohortsBin.fragments = {
  cohortBase: gql`
    fragment CohortsBin_CohortBase on CohortBase {
      id
      ...CohortButton_CohortBase
    }
    ${CohortButton.fragments.cohortBase}
  `,
};

export enum CohortSelectionMode {
  Selected = "SELECTED",
  NotSelected = "NOT_SELECTED",
}

type Props = {
  cohorts: CohortsBin_CohortBaseFragment[];
  selectMode: CohortSelectionMode;
  onClick(cohortId: Cohort["id"]): void;
};

export function CohortsBin({ cohorts, selectMode, onClick }: Props) {
  const isSelected = selectMode === CohortSelectionMode.Selected;

  return (
    <div
      className={clsx(
        "flex flex-row flex-wrap gap-2 justify-start content-start",
        "w-full h-36 p-2 overflow-y-auto",
        "border-solid border-2 rounded",
        isSelected ? "border-sky-500/60" : "border-slate-500/60"
      )}
    >
      {cohorts.map((cohort) => (
        <CohortButton
          key={cohort.id}
          cohort={cohort}
          isSelected={isSelected}
          onClick={onClick}
        />
      ))}
    </div>
  );
}
