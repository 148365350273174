import { gql } from "@apollo/client";
import {
  EducationDegree,
  TeachingExperienceCard_UserFragment,
} from "@generated/graphql";
import { getEducationDegreeText } from "@utils/labels";
import clsx from "clsx";
import { Card } from "components/shared";
import {
  CardDetailsRow,
  getEditModalOpenHeader,
  HeaderColors,
} from "components/shared/Card";
import { useState } from "react";
import { itemSubHeaderStyle } from "sections/Profile/constants";
import { getNoDataCallToAction } from "sections/Profile/helpers";
import { AddEditTeachingExperienceModal } from "./components/AddEditTeachingExperienceModal";

TeachingExperienceCard.fragments = {
  user: gql`
    fragment TeachingExperienceCard_User on User {
      id
      teachingExperience {
        id
        yearsOfExperience
        highestEducationDegree

        hasEslOrEllCertification
        hasTeflOrTesolCertification
        hasSpecialEducationCertification
        hasBilingualInstructionCertification

        hasApOrIbExperience
        hasSatOrActTestPrepExperience
        hasBilingualInstructionExperience
        hasCollegeAndCareerReadinessExperience
        ...AddEditTeachingExperienceModal_TeachingExperience
      }
    }
    ${AddEditTeachingExperienceModal.fragments.teachingExperience}
  `,
};

type Props = {
  user: TeachingExperienceCard_UserFragment;
  className?: string;
  forAdmin?: boolean;
  refetch?: () => void;
};

export function TeachingExperienceCard({
  user,
  className,
  forAdmin = false,
  refetch,
}: Props) {
  const { teachingExperience: xp, id: userId } = user;
  const { NoDegreeInEducation } = EducationDegree;

  const [showModal, setShowModal] = useState(false);

  const header = !forAdmin
    ? getEditModalOpenHeader("Teaching Experience", () => setShowModal(true))
    : "Teaching Experience";

  const certifications = [
    ...[xp?.hasEslOrEllCertification && "ESL/ELL"],
    ...[xp?.hasTeflOrTesolCertification && "TEFL/TESOL"],
    ...[xp?.hasSpecialEducationCertification && "Special Education"],
    ...[xp?.hasBilingualInstructionCertification && "Bilingual Instruction"],
  ]
    .filter(Boolean)
    .join(", ");

  const experience = [
    ...[xp?.hasApOrIbExperience && "AP/IB"],
    ...[xp?.hasSatOrActTestPrepExperience && "SAT/ACT Test Prep"],
    ...[xp?.hasBilingualInstructionExperience && "Bilingual Instruction"],
    ...[
      xp?.hasCollegeAndCareerReadinessExperience &&
        "College and Career Readiness",
    ],
  ]
    .filter(Boolean)
    .join(", ");

  const rows = xp
    ? ([
        [
          "Years of Professional Teaching Experience",
          xp?.yearsOfExperience ?? 0,
        ],
        [
          "Highest Degree in Education",
          getEducationDegreeText(
            xp?.highestEducationDegree ?? NoDegreeInEducation
          ),
        ],
        ["Additional Certifications", certifications],
        ["Other Experience", experience],
      ] as CardDetailsRow[])
    : undefined;

  return (
    <>
      <Card
        rows={rows}
        icon="requirements"
        className={className}
        headerOverlayColor={HeaderColors.Purple}
        header={header}
      >
        {!xp &&
          (forAdmin ? (
            <div className={clsx(itemSubHeaderStyle, "flex-center p-3")}>
              This user has not yet defined their teaching experience.
            </div>
          ) : (
            getNoDataCallToAction(
              () => setShowModal(true),
              `Please let us know about your teaching experience.`
            )
          ))}
      </Card>

      <AddEditTeachingExperienceModal
        experience={xp}
        userId={userId}
        show={showModal}
        closeModal={() => {
          refetch?.();
          setShowModal(false);
        }}
      />
    </>
  );
}
