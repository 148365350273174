/* eslint-disable react/jsx-key */
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { Icon, Spinner, TablePagination, Tooltip } from "components/shared";
import { CONTEXT_MENU_ID } from "components/shared/Table/constants";
import { useEffect, useRef, useState } from "react";
import {
  Column,
  Row,
  SortingRule,
  TableRowProps,
  UseTableOptions,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useDebounce } from "use-debounce";
import { EmptyTableState } from "./EmptyTableState";
import { renderSortIndicator } from "./helpers";

const DEBOUNCE_DELAY = 300;

type OnTableChangeArgs<D> = {
  pageIndex: number;
  pageSize: number;
  sortBy: SortingRule<D>[];
};

export type OnTableChange<D> = (props: OnTableChangeArgs<D>) => void;

export type TableControl<D> = {
  loading: boolean;
  pageCount: number;
  onTableChange: OnTableChange<D>;
};

/**
 * The AdvancedTable requires an id property for whatever data is being passed
 * into the table. Extend this type to ensure that the id property is present.
 */
export type AdvancedTableMinimumFragment = { id: string };

type Props<Fragment extends AdvancedTableMinimumFragment> = {
  data: Fragment[];
  dataName?: string;
  className?: string;
  cellPadding?: string;
  hiddenColumns?: string[];
  totalResultCount: number;
  selectedId?: string | null;
  columns: Column<Fragment>[];
  emptyStateIcon?: React.ReactNode;
  tableControl: TableControl<Fragment>;
  initialState?: UseTableOptions<Fragment>["initialState"];
  onRowClick?: (row: Row<Fragment>) => void;
  getRowProps?: (row: Row<Fragment>) => TableRowProps;
};

export function AdvancedTable<Fragment extends AdvancedTableMinimumFragment>({
  data,
  columns,
  dataName,
  emptyStateIcon,
  className,
  selectedId,
  initialState,
  hiddenColumns,
  totalResultCount,
  cellPadding = "px-3 py-3",
  tableControl: { onTableChange, loading, pageCount: controlledPageCount },
  onRowClick,
}: Props<Fragment>) {
  const {
    headerGroups,
    state: { pageIndex, pageSize, sortBy },
    prepareRow,
    getTableProps,
    getTableBodyProps,
    //Pagination
    page,
    pageCount,
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    previousPage,
    setHiddenColumns,
  } = useTable(
    {
      data,
      columns,
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
      initialState: {
        pageIndex: 0,
        ...initialState,
      },
    },
    useSortBy,
    usePagination
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const sortedHeaderContentRef = useRef<HTMLDivElement>(null);

  const hasData = page.length > 0;
  const needsPagination = page.length > 5 || pageCount > 1;

  const [onTableChangeDebounced] = useDebounce(onTableChange, DEBOUNCE_DELAY);
  const [sortIndicatorPxLeft, setSortIndicatorPxLeft] = useState(0);

  useEffect(() => {
    if (sortedHeaderContentRef.current) {
      const colWidth = sortedHeaderContentRef.current?.offsetWidth || 0;
      setSortIndicatorPxLeft(colWidth / 2);
    }
  }, [sortBy]);

  useEffect(() => {
    setHiddenColumns(hiddenColumns ?? []);
    onTableChangeDebounced({ pageIndex, pageSize, sortBy });
  }, [
    onTableChangeDebounced,
    pageIndex,
    pageSize,
    sortBy,
    hiddenColumns,
    setHiddenColumns,
  ]);

  // Automatically navigate to the last page if a new search causes the results
  // to be less than the current page.
  useEffect(() => {
    if (!loading && totalResultCount < pageIndex * pageSize) {
      gotoPage(Math.floor(totalResultCount / pageSize));
    }
  }, [loading, totalResultCount, pageIndex, pageSize, gotoPage]);

  return (
    <div className={clsx("w-full rounded-lg shadow-sm")}>
      <div
        ref={containerRef}
        className={clsx(
          "bg-white border-gray-200 overflow-x-auto border rounded-lg",
          hasData && needsPagination && "border-b-0 rounded-b-none",
          getScrollbarStyle("gray"),
          className
        )}
      >
        <table
          className="min-w-full divide-gray-200 divide-y"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => {
              const headerGroupProps = headerGroup.getHeaderGroupProps();

              return (
                <tr {...headerGroupProps} key={headerGroupProps.key}>
                  {headerGroup.headers.map((column) => {
                    const left = "0px";

                    const {
                      isSorted,
                      minWidth,
                      maxWidth,
                      isSortedDesc,
                      width: colWidth,
                    } = column;

                    const width =
                      colWidth !== 150 ? `${column.width}px` : "auto";

                    const headerProps = column.getHeaderProps();

                    return (
                      <th
                        scope="col"
                        key={headerProps.key || column.id}
                        role={headerProps.role || "columnheader"}
                        className={clsx(
                          "relative w-full",
                          column.id === CONTEXT_MENU_ID
                            ? "!min-w-[42px]"
                            : `${cellPadding} py-3`,
                          "text-left text-gray-500 text-xs font-medium tracking-wider uppercase bg-gray-50",
                          column.width
                        )}
                        style={{
                          width,
                          left,
                          minWidth,
                          maxWidth,
                          ...headerProps.style,
                        }}
                      >
                        <div
                          className="flex w-full"
                          {...(column.id === CONTEXT_MENU_ID
                            ? {}
                            : column.getSortByToggleProps())}
                        >
                          <div ref={isSorted ? sortedHeaderContentRef : null}>
                            {column.render("Header")}
                          </div>
                        </div>

                        {renderSortIndicator(
                          isSorted,
                          isSortedDesc,
                          sortIndicatorPxLeft,
                          cellPadding
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>

          {hasData && (
            <tbody
              className="bg-white divide-gray-200 divide-y"
              {...getTableBodyProps()}
              data-test="table"
            >
              {loading ? (
                <tr>
                  <td
                    className="px-6 py-4 text-gray-500 text-sm"
                    colSpan={columns.length}
                  >
                    <Spinner size={10} color="text-blue-600" />
                  </td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  const isRowSelected = row.original.id === selectedId;
                  const rowProps = row.getRowProps();

                  return (
                    <tr
                      {...rowProps}
                      key={rowProps.key}
                      className={clsx([
                        isRowSelected ? "bg-blue-100" : "hover:bg-gray-50",
                        onRowClick ? "cursor-pointer" : "cursor-default",
                      ])}
                    >
                      {row.cells.map((cell) => {
                        const onClick =
                          onRowClick && cell.column.id !== CONTEXT_MENU_ID
                            ? { onClick: () => onRowClick(row) }
                            : {};
                        const cellToRender =
                          cell.column.id === CONTEXT_MENU_ID ? (
                            <div className="flex justify-center">
                              {cell.render("Cell")}
                            </div>
                          ) : (
                            cell.render("Cell")
                          );

                        const cellProps = cell.getCellProps();

                        return (
                          <td
                            {...cellProps}
                            key={cellProps.key}
                            {...onClick}
                            className={clsx(
                              cell.column.id === CONTEXT_MENU_ID
                                ? "px-1 py-0"
                                : "p-3",
                              "text-gray-500 text-sm",
                              cell.column.width
                            )}
                          >
                            {cellToRender}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          )}
        </table>

        {!hasData && (
          <EmptyTableState
            dataName={dataName}
            emptyStateIcon={emptyStateIcon}
            loading={loading}
          />
        )}
      </div>

      {hasData && needsPagination && (
        <TablePagination
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          totalResultCount={totalResultCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      )}
    </div>
  );
}

type AdvancedSearchTooltipProps = { children: React.ReactNode };

export const AdvancedSearchTooltip = ({
  children,
}: AdvancedSearchTooltipProps) => (
  <Tooltip
    className="cursor-pointer ml-1"
    content={<div className="block w-48 text-sm text-center">{children}</div>}
    tooltipProps={{ place: "bottom" }}
  >
    <Icon icon="info" size={4} color="text-blue-600" />
  </Tooltip>
);
