import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  SortingRule,
  UseTableOptions,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { Spinner } from "../Loading/Spinner";
import { EmptyTableState } from "./EmptyTableState";
import { TablePagination } from "./TablePagination";
import { CONTEXT_MENU_ID } from "./constants";
import { renderSortIndicator } from "./helpers";

export type CustomColumn<D extends Record<string, unknown>> = Column<D> & {
  freeze?: boolean;
};

export type CustomColumnInstance<D extends Record<string, unknown>> =
  ColumnInstance<D> & {
    freeze?: boolean;
  };

export type CustomHeaderGroup<D extends Record<string, unknown>> =
  HeaderGroup<D> & { freeze?: boolean };

export type CustomCell<D extends Record<string, unknown>> = Cell<D> & {
  freeze?: boolean;
};

export type Pagination = {
  pageSize: number;
  pageIndex: number;
  totalRows: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
};

type Props<D extends Record<string, unknown>> = {
  data: D[];
  border?: boolean;
  loading?: boolean;
  dataName?: string;
  pageSize?: number;
  className?: string;
  cellPadding?: string;
  emptyStateIcon?: ReactNode;
  showHeaders?: boolean;
  pagination?: Pagination;
  freezeHeaders?: boolean;
  showPagination?: boolean;
  emptyStateTitle?: string;
  selectedRowIds?: string[];
  tableComponentId?: string;
  verticalDividers?: boolean;
  emptyStateSubtitle?: string;
  columns: CustomColumn<D>[] | Column<D>[];
  initialState?: UseTableOptions<D>["initialState"];
  fetchMoreData?: () => void;
  onRowClick?: (row: Row<D>) => void;
  onSortChange?: (sortBy: SortingRule<D>[]) => void;
};

export function Table<D extends { id: string }>({
  data,
  columns,
  dataName,
  className,
  emptyStateIcon,
  pagination,
  initialState,
  pageSize = 25,
  border = true,
  selectedRowIds,
  loading = false,
  emptyStateTitle,
  emptyStateSubtitle,
  showHeaders = true,
  freezeHeaders = false,
  showPagination = false,
  verticalDividers = false,
  cellPadding = "px-3 py-3",
  tableComponentId = "table-bottom-marker",
  onRowClick,
  onSortChange,
  fetchMoreData,
}: Props<D>) {
  const manualSorting = onSortChange ? true : false;

  const {
    rows,
    headerGroups,
    prepareRow,
    getTableProps,
    getTableBodyProps,
    //Pagination
    page,
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    setPageSize,
    previousPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      data,
      columns,
      manualSortBy: manualSorting,
      initialState: { pageIndex: 0, ...initialState },
    },
    useSortBy,
    usePagination,
    useSticky
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const sortedHeaderContentRef = useRef<HTMLDivElement>(null);

  const [isHScrolled, setIsHScrolled] = useState(false);
  const [rowHoverIndex, setRowHoverIndex] = useState<number>();
  const [sortIndicatorPxLeft, setSortIndicatorPxLeft] = useState(0);

  useEffect(() => {
    if (sortedHeaderContentRef.current) {
      const colWidth = sortedHeaderContentRef.current?.offsetWidth || 0;
      setSortIndicatorPxLeft(colWidth / 2);
    }
  }, [sortBy]);

  useEffect(() => {
    // Create an observer instance
    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach(
          (e) => e.isIntersecting && !loading && fetchMoreData?.()
        ),
      { threshold: 0.1 }
    );

    const tableBottomElement = document.getElementById(tableComponentId);
    if (tableBottomElement) observer.observe(tableBottomElement);

    return () => {
      if (tableBottomElement) observer.unobserve(tableBottomElement);
      observer.disconnect();
    };
  }, [fetchMoreData, loading, tableComponentId]);

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () =>
      container && setIsHScrolled(container.scrollLeft > 0);

    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    setPageSize(pagination?.pageSize ?? pageSize);
  }, [pageSize, pagination?.pageSize, setPageSize]);

  useEffect(() => {
    onSortChange?.(sortBy);
  }, [sortBy, onSortChange]);

  const pageCount = Math.ceil(
    (pagination?.totalRows ? pagination.totalRows : rows.length) / pageSize
  );

  const hasData = page.length > 0;
  const showPaginationControls =
    showPagination ||
    rows.length > pageSize ||
    pageCount > 1 ||
    (pagination?.totalRows ? pagination.totalRows > pageSize : false);

  const renderTableRows = page.map((row, rowIndex) => {
    prepareRow(row);
    const onClick = onRowClick ? { onClick: () => onRowClick(row) } : {};

    const isSelectedRow = (selectedRowIds ?? []).includes(row.original.id);
    const isSelectable = (selectedRowIds ?? []).length > 0;
    const rowIsHovered = rowHoverIndex === rowIndex;

    const rowProps = row.getRowProps();

    return (
      <tr
        {...rowProps}
        key={rowProps.key}
        className={clsx(
          "hover:bg-gray-50",
          isSelectable && "hover:bg-indigo-50",
          isSelectedRow && "bg-indigo-100 hover:bg-indigo-100 border-slate-400",
          onRowClick || isSelectable ? "cursor-pointer" : "cursor-default",
          verticalDividers && isSelectedRow
            ? "divide-slate-300"
            : "divide-gray-200",
          verticalDividers && "divide-x"
        )}
        onMouseOver={() => setRowHoverIndex(rowIndex)}
        onMouseLeave={() => setRowHoverIndex(undefined)}
        {...onClick}
      >
        {row.cells.map((cell, cellIndex) => {
          const cellToRender =
            cell.column.id === CONTEXT_MENU_ID ? (
              <div className="flex justify-center">{cell.render("Cell")}</div>
            ) : (
              cell.render("Cell")
            );

          let left = "0px";
          const columnInstance = cell.column as CustomColumnInstance<D>;
          const { minWidth: minW, maxWidth: maxW } = columnInstance;
          const colWidth = columnInstance.width ?? 150;
          const freeze = columnInstance.freeze ?? false;
          const width = colWidth !== 150 ? `${colWidth}px` : "auto";

          const minWidth = minW === 0 ? minW : `${minW}px`;
          const maxWidth = maxW === 0 ? maxW : `${maxW}px`;

          if (freeze) {
            const prevCols = row.cells.slice(0, cellIndex);
            left = `${prevCols.reduce(
              (sum, cell) => sum + Number(cell.column.width),
              0
            )}px`;
          }

          let bgColor = rowIsHovered
            ? isSelectable
              ? "bg-indigo-50"
              : "bg-gray-50"
            : freeze
            ? "bg-white"
            : "bg-none";
          bgColor = isSelectedRow ? "bg-indigo-100" : bgColor;

          const cellProps = cell.getCellProps();

          return (
            <td
              role="cell"
              key={cellProps.key}
              className={clsx(
                cell.column.id === CONTEXT_MENU_ID ? "px-0 py-0" : cellPadding,
                "text-gray-500 text-sm",
                freeze && "sticky z-[200]",
                bgColor
              )}
              onClick={(e) =>
                cell.column.id === CONTEXT_MENU_ID && e.stopPropagation()
              }
              style={{ width, left, minWidth, maxWidth, ...cellProps.style }}
            >
              {cellToRender}
              {freeze && isHScrolled && (
                <div className="absolute h-full w-[2px] z-[1000] right-0 top-0 bg-gray-200" />
              )}
            </td>
          );
        })}
      </tr>
    );
  });

  const nextPageFunction = pagination?.gotoPage
    ? () => pagination.gotoPage(pagination.pageIndex + 1)
    : () => nextPage();

  const previousPageFunction = pagination?.gotoPage
    ? () => pagination.gotoPage(pagination.pageIndex - 1)
    : () => previousPage();

  return (
    <div className={clsx("w-full rounded-lg shadow-sm")}>
      <div
        ref={containerRef}
        className={clsx(
          "bg-white overflow-x-auto ",
          border ? "border border-gray-200 rounded-lg" : "border-none",
          hasData && !className ? "min-h-[370px]" : className,
          hasData && showPaginationControls && "border-b-0 rounded-b-none",
          getScrollbarStyle("gray")
        )}
      >
        <table
          className="min-w-full divide-gray-200 divide-y"
          {...getTableProps()}
        >
          {showHeaders && (
            <thead>
              {headerGroups.map((headerGroup, headerGroupIndex) => {
                const { headers } = headerGroup;
                const headerGroupProps = headerGroup.getHeaderGroupProps();

                return (
                  <tr
                    {...headerGroupProps}
                    key={headerGroupProps.key}
                    className={clsx(
                      verticalDividers && "divide-gray-200 divide-x",
                      freezeHeaders && "sticky top-0 z-[100] bg-white"
                    )}
                  >
                    {headers.map((column: CustomHeaderGroup<D>) => {
                      let left = "0px";
                      const freeze = column.freeze ?? false;

                      const {
                        isSorted,
                        minWidth,
                        maxWidth,
                        isSortedDesc,
                        width: colWidth,
                      } = column;

                      const width = colWidth !== 150 ? `${colWidth}px` : "auto";

                      if (freeze) {
                        const prevCols = headers.slice(0, headerGroupIndex);
                        left = `${prevCols.reduce(
                          (sum, col) => sum + Number(col.width),
                          0
                        )}px`;
                      }

                      const headerProps = column.getHeaderProps();

                      return (
                        <th
                          scope="col"
                          key={headerProps.key || column.id}
                          role={headerProps.role || "columnheader"}
                          className={clsx(
                            "bg-gray-50 relative",
                            column.id === CONTEXT_MENU_ID
                              ? "!min-w-[42px]"
                              : `${cellPadding} py-3`,
                            "text-left text-gray-500 text-xs font-medium tracking-wider uppercase",
                            freeze && "!sticky z-[200] border-r border-gray-200"
                          )}
                          style={{
                            width,
                            left,
                            minWidth,
                            maxWidth,
                            ...headerProps.style,
                          }}
                        >
                          <div
                            className="flex w-full relative"
                            {...(column.id === CONTEXT_MENU_ID
                              ? {}
                              : column.getSortByToggleProps())}
                          >
                            <div ref={isSorted ? sortedHeaderContentRef : null}>
                              {column.render("Header")}
                            </div>
                          </div>

                          {renderSortIndicator(
                            isSorted,
                            isSortedDesc,
                            sortIndicatorPxLeft,
                            cellPadding
                          )}

                          {freeze && isHScrolled && (
                            <div className="absolute h-full w-[2px] right-0 top-0 bg-gray-200" />
                          )}
                          {freezeHeaders && (
                            <div className="absolute w-full h-px bg-slate-200 z-[2000] bottom-0 left-0" />
                          )}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
          )}

          {hasData && (
            <tbody
              data-test="table"
              className="bg-white divide-gray-200 divide-y"
              {...getTableBodyProps()}
            >
              {loading && !fetchMoreData ? (
                <tr>
                  <td
                    className="w-full px-6 py-4 text-gray-500 text-sm"
                    colSpan={columns.length}
                  >
                    <Spinner
                      size={10}
                      color="text-blue-600"
                      className="mx-auto"
                    />
                  </td>
                </tr>
              ) : (
                renderTableRows
              )}
              {fetchMoreData && (
                <tr className="!border-none" id={tableComponentId} />
              )}
            </tbody>
          )}
        </table>

        {!hasData && (
          <EmptyTableState
            dataName={dataName}
            emptyStateIcon={emptyStateIcon}
            title={emptyStateTitle}
            subtitle={emptyStateSubtitle}
            loading={loading}
          />
        )}
      </div>

      {showPaginationControls && hasData && (
        <TablePagination
          pageIndex={pagination?.pageIndex ?? pageIndex}
          totalResultCount={pagination?.totalRows ?? rows.length}
          pageCount={pageCount}
          gotoPage={pagination?.gotoPage ?? gotoPage}
          canPreviousPage={pagination?.canPreviousPage ?? canPreviousPage}
          canNextPage={pagination?.canNextPage ?? canNextPage}
          nextPage={nextPageFunction}
          previousPage={previousPageFunction}
        />
      )}
    </div>
  );
}
