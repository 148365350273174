import { gql } from "@apollo/client";
import { Cohort, CohortButton_CohortBaseFragment } from "@generated/graphql";
import { guardTypename } from "@utils/types";
import clsx from "clsx";
import { Icon, Tooltip } from "components/shared";
import { CohortButtonTooltipContent } from "./CohortButtonTooltipContent";

CohortButton.fragments = {
  cohortBase: gql`
    fragment CohortButton_CohortBase on CohortBase {
      id
      ... on CohortSimple {
        name
      }
      ... on Cohort {
        name
        ...CohortButtonTooltipContent_Cohort
      }
    }
    ${CohortButtonTooltipContent.fragments.cohort}
  `,
};

type Props = {
  cohort: CohortButton_CohortBaseFragment;
  isSelected: boolean;
  onClick(cohortId: Cohort["id"]): void;
};

export function CohortButton({ cohort, isSelected, onClick }: Props) {
  const isCohortSimple = guardTypename(cohort, "CohortSimple");
  const isCohort = guardTypename(cohort, "Cohort");

  if (!isCohortSimple && !isCohort) return null;

  return (
    <Tooltip
      disabled={!isCohort}
      tooltipProps={{ place: "top", delayShow: 250 }}
      content={isCohort && <CohortButtonTooltipContent cohort={cohort} />}
    >
      <div
        className={clsx(
          "relative flex items-center py-1.5 px-3 rounded-full",
          "cursor-pointer select-none",
          isSelected
            ? "bg-sky-200 hover:bg-sky-100"
            : "bg-slate-200 hover:bg-slate-100"
        )}
        onClick={() => onClick(cohort.id)}
      >
        <div className="absolute hover:opacity-100 opacity-0 inset-0 w-full h-full">
          <Icon
            icon={isSelected ? "subtract" : "add"}
            color={isSelected ? "text-rose-500" : "text-sky-500"}
            size={5}
            className="absolute -top-1 -right-1 bg-white rounded-full"
          />
        </div>
        <span className="text-sm font-semibold max-w-[20ch] truncate">
          {cohort.name}
        </span>
      </div>
    </Tooltip>
  );
}
