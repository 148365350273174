import { gql } from "@apollo/client";
import {
  Cohort,
  CohortsBulkSelect_CohortBaseFragment,
} from "@generated/graphql";
import { Icon, Tooltip } from "components/shared";
import { CohortSelectionMode, CohortsBin } from "./components/CohortsBin";

CohortsBulkSelect.fragments = {
  cohortBase: gql`
    fragment CohortsBulkSelect_CohortBase on CohortBase {
      id
      ...CohortsBin_CohortBase
    }
    ${CohortsBin.fragments.cohortBase}
  `,
};

type Props = {
  selectedCohorts: CohortsBulkSelect_CohortBaseFragment[];
  deselectedCohorts: CohortsBulkSelect_CohortBaseFragment[];
  setSelectedCohortIds: React.Dispatch<React.SetStateAction<Cohort["id"][]>>;
  selectedTooltipContent?: React.ReactNode;
};

export function CohortsBulkSelect({
  selectedCohorts,
  deselectedCohorts,
  setSelectedCohortIds,
  selectedTooltipContent,
}: Props) {
  const selectCohort = (cohortId: Cohort["id"]) => {
    setSelectedCohortIds((prev) => [...prev, cohortId]);
  };
  const deselectCohort = (cohortId: Cohort["id"]) => {
    setSelectedCohortIds((prev) => prev.filter((id) => id !== cohortId));
  };

  return (
    <div className="grid grid-cols-9 gap-y-1">
      <div className="col-span-4 text-center">
        <h2 className="font-medium text-gray-600">Not Selected</h2>
      </div>
      <div className="col-span-1" />
      <div className="col-span-4 flex flex-row justify-center gap-x-1 text-center">
        <h2 className="font-medium text-gray-600">Selected</h2>
        {selectedTooltipContent && (
          <Tooltip
            className="cursor-help"
            tooltipProps={{ place: "top", delayShow: 250 }}
            content={selectedTooltipContent}
          />
        )}
      </div>
      <div className="col-span-4">
        <CohortsBin
          cohorts={deselectedCohorts}
          selectMode={CohortSelectionMode.NotSelected}
          onClick={selectCohort}
        />
      </div>
      <div className="flex flex-col flex-center col-span-1 gap-y-2">
        <Tooltip
          content="Select all Cohorts"
          tooltipProps={{ place: "top", delayShow: 250 }}
          className="bg-sky-500 rounded-full p-1.5"
        >
          <Icon
            icon="anglesRight"
            size={7}
            color="text-white"
            onClick={() =>
              setSelectedCohortIds((prev) =>
                prev.concat(deselectedCohorts.map(({ id }) => id))
              )
            }
          />
        </Tooltip>
        <Tooltip
          content="De-select all Cohorts"
          tooltipProps={{ place: "bottom", delayShow: 250 }}
          className="bg-rose-500 rounded-full p-1.5"
        >
          <Icon
            icon="anglesLeft"
            size={7}
            color="text-white"
            onClick={() => setSelectedCohortIds([])}
          />
        </Tooltip>
      </div>
      <div className="col-span-4">
        <CohortsBin
          cohorts={selectedCohorts}
          selectMode={CohortSelectionMode.Selected}
          onClick={deselectCohort}
        />
      </div>
    </div>
  );
}
