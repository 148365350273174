import { CohortAssignmentRole } from "@generated/graphql";
import { getCohortAssignmentRoleText } from "@utils/labels";
import { assertUnreachable } from "@utils/types";
import clsx from "clsx";
import { Badge } from "components/shared";

export type CohortSessionRole = CohortAssignmentRole.PrimaryTeacher | "IS";

type Props = {
  role: CohortSessionRole;
  className?: string;
};

export const CohortSessionRoleBadge = ({ role, className }: Props) => {
  return (
    <Badge
      className={clsx(
        cohortAssignmentRoleColors[role],
        "whitespace-nowrap my-1",
        className
      )}
    >
      {getCohortSessionRoleText(role)}
    </Badge>
  );
};

const getCohortSessionRoleText = (role: CohortSessionRole): string => {
  switch (role) {
    case CohortAssignmentRole.PrimaryTeacher:
      return getCohortAssignmentRoleText("long");
    case "IS":
      return "Instructional Support Teacher";
    default:
      return assertUnreachable(role, "Cohort Session Role");
  }
};

const cohortAssignmentRoleColors = {
  [CohortAssignmentRole.PrimaryTeacher]: "text-cyan-900 bg-cyan-200",
  [CohortAssignmentRole.SubstituteTeacher]: "text-fuchsia-900 bg-fuchsia-200",
  ["IS"]: "text-indigo-900 bg-indigo-200",
};
