import { gql } from "@apollo/client";
import { CohortButtonTooltipContent_CohortFragment } from "@generated/graphql";
import { normalizeToUtcISODate } from "@utils/dateTime";
import { getGradeLevelText, getLanguageText } from "@utils/labels";
import { getEventString } from "@utils/withFragments/events";
import clsx from "clsx";

CohortButtonTooltipContent.fragments = {
  cohort: gql`
    fragment CohortButtonTooltipContent_Cohort on Cohort {
      id
      name
      endDate
      language
      startDate
      adminNotes
      hourlyTTRate
      instructionLevel
      instructionNotes
      events {
        id
        ...GetEventString_CohortEvent
      }
      talentLMSCourses {
        id
        name
        courseId
      }
    }
    ${getEventString.fragments.cohortEvent}
  `,
};
type Props = {
  cohort: CohortButtonTooltipContent_CohortFragment;
};

export function CohortButtonTooltipContent({ cohort }: Props) {
  return (
    <div className="grid grid-cols-2 gap-x-2 gap-y-0 text-sm text-white max-w-[50ch]">
      <Row label="ID" value={cohort.id} />
      <Row label="Name" value={cohort.name} />
      <Row
        label="Instruction Level"
        value={getGradeLevelText(cohort.instructionLevel)}
      />
      <Row
        label="Start Date"
        value={normalizeToUtcISODate(new Date(cohort.startDate))}
      />
      <Row
        label="End Date"
        value={normalizeToUtcISODate(new Date(cohort.endDate))}
      />
      <Row
        label="Language"
        value={cohort.language && getLanguageText(cohort.language)}
        fallbackValue="Not specified"
      />
      <Row
        label="TT Pay Rate"
        value={
          cohort.hourlyTTRate != null ? `$${cohort.hourlyTTRate}/hour` : null
        }
        fallbackValue="(Inherited from Engagement)"
      />
      <Row label="Admin Notes" value={cohort.adminNotes} />
      <Row label="Instruction Notes" value={cohort.instructionNotes} />
      <div className="flex flex-col col-span-full text-center mt-1">
        <p className="font-semibold">Schedule</p>
        {cohort.events.map((event) => (
          <p key={event.id}>{getEventString(event)}</p>
        ))}
      </div>
      {cohort.talentLMSCourses.length > 0 && (
        <div className="flex flex-col col-span-full text-center mt-1">
          <p className="font-semibold">PD Curriculum Courses </p>
          {cohort.talentLMSCourses.map((course) => (
            <p key={course.id}>{course.name}</p>
          ))}
        </div>
      )}
    </div>
  );
}

type RowProps = {
  label: string;
  value: string | null | undefined;
  fallbackValue?: string | null; // Set to null to hide the row if value is false-y.
};

function Row({ label, value, fallbackValue = "(None)" }: RowProps) {
  if (!value && fallbackValue === null) return null;

  return (
    <>
      <p className="text-end font-semibold">{label}</p>
      <p className={clsx("text-start truncate", !value && "italic")}>
        {value || fallbackValue}
      </p>
    </>
  );
}
