import { gql } from "@apollo/client";
import { TeachingExperienceChecklistItem_UserFragment } from "@generated/graphql";
import clsx from "clsx";
import { ChecklistItem } from "components/shared";
import { useState } from "react";
import {
  itemContentStyle,
  itemSubContentStyle,
} from "sections/Profile/constants";
import { AddEditTeachingExperienceModal } from "../../TeachingExperienceCard/components/AddEditTeachingExperienceModal";

TeachingExperienceChecklistItem.fragments = {
  user: gql`
    fragment TeachingExperienceChecklistItem_User on User {
      id
      teachingExperience {
        id
        userId
        yearsOfExperience
        highestEducationDegree
        ...AddEditTeachingExperienceModal_TeachingExperience
      }
    }
    ${AddEditTeachingExperienceModal.fragments.teachingExperience}
  `,
};

type Props = {
  user: TeachingExperienceChecklistItem_UserFragment;
  refetch: () => void;
};

export function TeachingExperienceChecklistItem({
  user: { id: userId, teachingExperience: xp },
  refetch,
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const { highestEducationDegree, yearsOfExperience } = xp || {};

  let completeCount = 0;
  let incompleteCount = 0;

  yearsOfExperience ? ++completeCount : ++incompleteCount;
  highestEducationDegree ? ++completeCount : ++incompleteCount;

  const completionText =
    incompleteCount > 0
      ? `(${Math.floor(
          (completeCount / (completeCount + incompleteCount)) * 100
        )}% complete)`
      : "";

  return (
    <>
      <ChecklistItem
        trackedForCompletion
        complete={incompleteCount === 0}
        title={
          <div className="flex items-center gap-x-1">
            {`Fill out your`}
            <span
              className={clsx(
                "underline-offset-2 decoration-gray-400/90 cursor-pointer",
                "hover:decoration-blue-400",
                incompleteCount > 0 && "underline "
              )}
              onClick={() => setShowModal(true)}
            >
              Teaching Experience
            </span>
            {completionText}
          </div>
        }
        collapsibleContent={
          incompleteCount > 0 ? (
            <>
              <p className={itemContentStyle}>
                To complete this item, you still need to fill out the following:
              </p>
              <ul
                className={clsx(
                  "list-disc list-inside *:ml-1",
                  itemSubContentStyle
                )}
              >
                {!yearsOfExperience && <li>Years of experience</li>}
                {!highestEducationDegree && <li>Highest Education Degree</li>}
              </ul>
            </>
          ) : null
        }
      />

      <AddEditTeachingExperienceModal
        userId={userId}
        show={showModal}
        experience={xp}
        closeModal={() => {
          refetch();
          setShowModal(false);
        }}
      />
    </>
  );
}
