import {
  CohortAssignmentSubject,
  TutoringPreferencesPosition,
} from "@generated/graphql";
import clsx from "clsx";

export const itemSectionHeaderStyle =
  "text-[18px] font-bold text-gray-700 leading-5";
export const itemHeaderStyle =
  "text-[16px] font-semibold text-gray-700 leading-5";
export const itemSubHeaderStyle =
  "text-[14px] font-semibold text-gray-700 leading-5";
export const itemContentStyle =
  "text-[14px] font-medium text-gray-700 leading-5";
export const itemSubContentStyle =
  "text-[13px] font-medium text-gray-600 leading-4";
export const listContentStyle =
  "text-[15px] font-medium text-gray-700 leading-5";

export const sectionWrapperStyle = "flex flex-col gap-y-2";
export const sectionBodyWrapperStyle =
  "flex items-center w-full h-fit p-3 border rounded-lg bg-slate-50/70";

export const languageItemStyle =
  "flex h-7 items-center text-[12px] leading-5 max-w-[250px] flex flex-wrap";

export const checklistLinkStyle = clsx(
  "flex items-center gap-x-[2px] underline-offset group ml-1",
  "text-blue-800/80 decoration-blue-800 cursor-pointer w-fit",
  "hover:decoration-blue-600 underline hover:text-blue-600"
);

export const cohortSubjectOptions: CohortAssignmentSubject[] = [
  CohortAssignmentSubject.Ela,
  CohortAssignmentSubject.SocialStudies,
  CohortAssignmentSubject.Math,
  CohortAssignmentSubject.Science,
  CohortAssignmentSubject.Eld,
];

type TeachingPositionMap = {
  [K in TutoringPreferencesPosition]: null;
};
const teachingPositionMap: TeachingPositionMap = {
  [TutoringPreferencesPosition.TeacherTutor]: null,
  [TutoringPreferencesPosition.InstructionalSupport]: null,
};
export const teachingPositionOptions = Object.keys(
  teachingPositionMap
) as TutoringPreferencesPosition[];

export const testPrepSubjectOptions: CohortAssignmentSubject[] = [
  CohortAssignmentSubject.Ela,
  CohortAssignmentSubject.Math,
];

export const esFluentQuestion = "Are you fluent in Spanish?";
export const esComfortableQuestion =
  "Are you comfortable teaching content in Spanish?";
export const enEsComfortableQuestion =
  "Are you comfortable teaching content in English with some Spanish support?";
export const additionalLanguagesQuestion = "Do you speak any other languages?";

export const ellEldTooltipText =
  "Are you comfortable working with students who are not yet fluent in English and are currently developing their English listening, speaking, reading, and writing skills?";
export const testPrepTooltipText =
  "Are you comfortable teaching test prep courses? If so, which subjects?";
