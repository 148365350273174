import { gql } from "@apollo/client";
import { MyProfilePage_UserFragment } from "@generated/graphql";
import { breadcrumbs } from "@utils/breadcrumbs";
import { getScrollbarStyle } from "@utils/styleStrings";
import { clsx } from "clsx";
import { PageHeader } from "components/shared";
import { HeightMode } from "components/shared/Card";
import { UserDetailsCard } from "components/shared/UserDetailsCard";
import { WelcomePanel } from "components/shared/WelcomePanel";
import {
  OnboardingChecklistCard,
  TeachingExperienceCard,
  TutoringPreferencesCard,
  UserReferralSourceCard,
} from "./components";

MyProfilePage.fragments = {
  user: gql`
    fragment MyProfilePage_User on User {
      id
      ...WelcomePanel_User
      ...UserDetailsCard_User
      ...UserReferralSourceCard_User
      ...TutoringPreferencesCard_User
      ...OnboardingChecklistCard_User
      ...TeachingExperienceCard_User
    }
    ${WelcomePanel.fragments.user}
    ${UserDetailsCard.fragments.user}
    ${UserReferralSourceCard.fragments.user}
    ${TutoringPreferencesCard.fragments.user}
    ${OnboardingChecklistCard.fragments.user}
    ${TeachingExperienceCard.fragments.user}
  `,
};

type Props = {
  isTutorTeacher: boolean;
  user: MyProfilePage_UserFragment;
  refetch: () => void;
};

export function MyProfilePage({ isTutorTeacher, user, refetch }: Props) {
  return (
    <div
      className={clsx(
        "h-screen-nav bg-slate-100 overflow-y-scroll",
        getScrollbarStyle("lightPage")
      )}
    >
      <div className="flex flex-col p-4 gap-4">
        <PageHeader
          breadcrumbs={[
            breadcrumbs.home(),
            breadcrumbs.myProfile({ current: true }),
          ]}
        />

        {isTutorTeacher && (
          <div className="grid grid-cols-2 gap-4">
            <WelcomePanel user={user} className="col-span-2" />

            <div className="flex flex-col gap-4 col-span-2 xl:col-span-1">
              <UserDetailsCard user={user} />
              <OnboardingChecklistCard refetch={refetch} user={user} />
            </div>

            <div className="flex flex-col gap-4 col-span-2 xl:col-span-1">
              <TeachingExperienceCard user={user} refetch={refetch} />
              <TutoringPreferencesCard
                user={user}
                heightMode={HeightMode.Fit}
                refetch={refetch}
              />
              <UserReferralSourceCard user={user} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
