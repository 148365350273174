import clsx from "clsx";
import { ReactNode, useState } from "react";
import { PillButton } from "../Buttons";
import { Icon } from "../Icon";

export type PillOption<O> = {
  value: O;
  content: ReactNode;
};

type Props<O> = {
  selected: O[];
  className?: string;
  hideIcons?: boolean;
  pillClassName?: string;
  options: PillOption<O>[];
  optionsClassName?: string;
  onChange: (selected: O[]) => void;
};

export const PillSelectInput = <O,>({
  options,
  selected,
  className,
  pillClassName,
  optionsClassName,
  hideIcons = false,
  onChange,
}: Props<O>) => {
  const [hoveredValue, setHoveredValue] = useState<O | null>(null);

  return (
    <div className={clsx("flex flex-col gap-y-4", className)}>
      <div className={clsx("flex gap-2 w-full", optionsClassName)}>
        {options.map((option, i) => {
          const isSelected = selected.includes(option.value);
          const isHovered = hoveredValue === option.value;

          return (
            <div
              key={i}
              className="flex w-fit h-fit"
              onMouseEnter={() => setHoveredValue(option.value)}
              onMouseLeave={() => setHoveredValue(null)}
            >
              <PillButton
                key={i}
                name={
                  <div className="flex-center gap-x-1 group text-nowrap">
                    {!hideIcons && (
                      <Icon
                        icon={
                          isSelected
                            ? isHovered
                              ? "remove"
                              : "checkmarkCircle"
                            : "add"
                        }
                        size={4}
                        color={clsx(
                          isSelected && !isHovered && "text-blue-600",
                          isSelected && isHovered && "text-rose-600",
                          !isSelected && !isHovered && "text-gray-300",
                          !isSelected && isHovered && "text-blue-400"
                        )}
                      />
                    )}
                    {option.content}
                  </div>
                }
                className={clsx(
                  "border rounded-full cursor-pointer",
                  hideIcons ? "px-2 py-1" : "pr-2 pl-1 py-1",
                  isSelected &&
                    !isHovered &&
                    "border-blue-600 bg-blue-100 !text-blue-900",
                  isSelected &&
                    isHovered &&
                    "bg-rose-200 text-rose-900 border-rose-500",
                  !isSelected &&
                    !isHovered &&
                    "bg-slate-50 text-slate-600 border-slate-300",
                  !isSelected &&
                    isHovered &&
                    "bg-blue-50 text-slate-900 border-blue-400",
                  pillClassName
                )}
                onChange={() => {
                  setHoveredValue(null);
                  onChange(
                    isSelected
                      ? selected.filter((s) => s !== option.value)
                      : [...selected, option.value]
                  );
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
