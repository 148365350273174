import { gql, useMutation } from "@apollo/client";
import {
  AddEditTeachingExperienceModalBody_TeachingExperienceFragment,
  AddEditTeachingExperienceModalMutation,
  AddEditTeachingExperienceModalMutationVariables,
  EducationDegree,
  User,
} from "@generated/graphql";
import { editErrToast } from "@utils/errorLogging";
import { getInputStyle } from "@utils/styleStrings";
import { InputNumber } from "antd";
import clsx from "clsx";
import { Button, Checkbox, Icon, Modal } from "components/shared";
import { EducationDegreeSelectMenu } from "components/shared/Inputs/SelectMenuOptions";
import { useEffect, useReducer, useState } from "react";
import {
  itemSectionHeaderStyle,
  listContentStyle,
  sectionBodyWrapperStyle,
  sectionWrapperStyle,
} from "sections/Profile/constants";
import {
  BoolSetter,
  CertIndex,
  ExperienceIndex,
  initialState,
  reducer,
} from "./teachingExperienceReducer";

const ADD_EDIT_TEACHING_EXPERIENCE_MUTATION = gql`
  mutation AddEditTeachingExperienceModal(
    $input: AddEditTeachingExperienceInput!
  ) {
    addEditTeachingExperience(input: $input) {
      id
    }
  }
`;

AddEditTeachingExperienceModalBody.fragments = {
  teachingExperience: gql`
    fragment AddEditTeachingExperienceModalBody_TeachingExperience on TeachingExperience {
      id
      yearsOfExperience
      highestEducationDegree

      hasEslOrEllCertification
      hasTeflOrTesolCertification
      hasSpecialEducationCertification
      hasBilingualInstructionCertification

      hasApOrIbExperience
      hasSatOrActTestPrepExperience
      hasBilingualInstructionExperience
      hasCollegeAndCareerReadinessExperience
    }
  `,
};

type Props = {
  userId: User["id"];
  experience:
    | AddEditTeachingExperienceModalBody_TeachingExperienceFragment
    | null
    | undefined;
  closeModal: () => void;
};

export function AddEditTeachingExperienceModalBody({
  userId,
  experience,
  closeModal,
}: Props) {
  const { NoDegreeInEducation } = EducationDegree;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [yearsOfExperience, setYearsOfExperience] = useState(0);
  const [highestDegree, setHighestDegree] = useState(NoDegreeInEducation);
  const { ApOrIbXp, TestPrepXp, BilingualXp, ReadinessXp } = ExperienceIndex;
  const { EslEllCert, TeflTesolCert, SpecialEdCert, BilingualCert } = CertIndex;

  useEffect(() => {
    const { NoDegreeInEducation } = EducationDegree;
    setYearsOfExperience(experience?.yearsOfExperience ?? 0);
    setHighestDegree(experience?.highestEducationDegree ?? NoDegreeInEducation);
    if (experience) dispatch({ type: "LOAD_DATA", payload: experience });
  }, [experience]);

  const [addEditTeachingExperience, { loading }] = useMutation<
    AddEditTeachingExperienceModalMutation,
    AddEditTeachingExperienceModalMutationVariables
  >(ADD_EDIT_TEACHING_EXPERIENCE_MUTATION, {
    onCompleted: closeModal,
    onError: (err) => editErrToast("your teaching experience.", err.message),
  });

  const getBoolItem = (title: string, isCerts: boolean, i: number) => {
    const currValue = state[isCerts ? "certifications" : "otherExperience"];
    const type = isCerts ? BoolSetter.SetCerts : BoolSetter.SetExperience;
    const payload = [...currValue];
    payload[i] = !payload[i];

    return (
      <div
        className="flex items-center gap-x-3 w-full cursor-pointer"
        onClick={() => dispatch({ type, payload })}
      >
        <Checkbox checked={currValue[i]} />
        <div className={listContentStyle}>{title}</div>
      </div>
    );
  };

  const onAddEditTeachingExperience = async () =>
    await addEditTeachingExperience({
      variables: {
        input: {
          userId,
          yearsOfExperience,
          highestEducationDegree: highestDegree,

          hasEslOrEllCertification: state.certifications[EslEllCert],
          hasTeflOrTesolCertification: state.certifications[TeflTesolCert],
          hasSpecialEducationCertification: state.certifications[SpecialEdCert],
          hasBilingualInstructionCertification:
            state.certifications[BilingualCert],

          hasApOrIbExperience: state.otherExperience[ApOrIbXp],
          hasSatOrActTestPrepExperience: state.otherExperience[TestPrepXp],
          hasBilingualInstructionExperience: state.otherExperience[BilingualXp],
          hasCollegeAndCareerReadinessExperience:
            state.otherExperience[ReadinessXp],
        },
      },
    });

  return (
    <div className="flex flex-col w-full px-3 pb-0 pt-5 gap-y-4">
      {/* Years of Experience */}
      <div className={sectionWrapperStyle}>
        <span className={itemSectionHeaderStyle}>
          Years of Professional Teaching Experience
        </span>
        <div className={sectionBodyWrapperStyle}>
          <InputNumber
            min={0}
            max={80}
            changeOnWheel
            value={yearsOfExperience}
            style={{
              ...getInputStyle,
              width: "60px",
              height: "38px",
              borderRadius: "6px",
            }}
            controls={{
              upIcon: <Icon icon="sortUp" size={3} />,
              downIcon: <Icon icon="sortDown" size={3} />,
            }}
            onStep={(value) => setYearsOfExperience(value)}
            onChange={(value) => setYearsOfExperience(value ?? 0)}
          />
        </div>
      </div>

      {/* Highest Degree in Education */}
      <div className={sectionWrapperStyle}>
        <span className={itemSectionHeaderStyle}>
          Highest Degree in Education
        </span>
        <div className={sectionBodyWrapperStyle}>
          <EducationDegreeSelectMenu
            className="w-[220px]"
            initialDegree={highestDegree}
            onSelect={(value) => setHighestDegree(value ?? NoDegreeInEducation)}
          />
        </div>
      </div>

      {/* Additional Certifications */}
      <div className={sectionWrapperStyle}>
        <span className={itemSectionHeaderStyle}>
          Additional Certifications
        </span>
        <div
          className={clsx(
            sectionBodyWrapperStyle,
            "flex-col !items-start gap-y-1"
          )}
        >
          {getBoolItem("ESL/ELL", true, EslEllCert)}
          {getBoolItem("TEFL/TESOL", true, TeflTesolCert)}
          {getBoolItem("Special Education", true, SpecialEdCert)}
          {getBoolItem("Bilingual Instruction", true, BilingualCert)}
        </div>
      </div>

      {/* Other Experience */}
      <div className={sectionWrapperStyle}>
        <span className={itemSectionHeaderStyle}>Other Experience</span>
        <div
          className={clsx(
            sectionBodyWrapperStyle,
            "flex-col !items-start gap-y-1"
          )}
        >
          {getBoolItem("AP/IB", false, ApOrIbXp)}
          {getBoolItem("SAT/ACT Test Prep", false, TestPrepXp)}
          {getBoolItem("Bilingual Instruction", false, BilingualXp)}
          {getBoolItem("College and Career Readiness", false, ReadinessXp)}
        </div>
      </div>

      <Modal.Buttons>
        <Button
          type="submit"
          loading={loading}
          onClick={onAddEditTeachingExperience}
        >
          Save
        </Button>
        <Modal.Button type="cancel" onClick={closeModal}>
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </div>
  );
}
