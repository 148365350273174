import { ApolloQueryResult } from "@apollo/client";
import { AccountRole } from "@generated/graphql";

export function assertUnreachable(x: never, name?: string): never {
  const errorString = `unreachable assertion failure${
    name ? `: ${name}` : `.`
  }`;

  throw new Error(errorString);
}

/**
 * Asserts that a value is defined.
 * Great for use in array filter functions.
 *
 * @param arg - The value to be checked.
 * @returns True if the value is defined, false otherwise.
 */
export function assertDefined<T>(arg: T | undefined): arg is T {
  return arg !== undefined;
}

export function fromJust<T>(t: T | null | undefined, nameForError?: string): T {
  if (t === null || t === undefined) {
    throw new Error(
      `Unexpected undefined/null value${
        nameForError == null ? "" : `: ${nameForError}`
      }`
    );
  }

  return t;
}

export function notNullOrUndefined<TValue>(
  value: TValue | null | undefined
): value is TValue {
  if (value === null || value === undefined) {
    return false;
  }
  // dummy variable is to give the compiler something to check at compile time.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const testDummy: TValue = value;

  return true;
}

/**
 * Returns a union type with the property keys of the schema model `M`,
 * excluding the identifying "__typename".
 */
export type SchemaModelKeys<M> = Exclude<keyof M, "__typename">;

/**
 * To satify very sensitive Input values, this will simple remove the __typename
 * property from whatever object you pass in.
 */
export function removeTypename<T extends { __typename?: string }>(
  obj: T
): Omit<T, "__typename"> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...rest } = obj;
  return rest;
}

/**
 * Typeguard for checking the __typename of a model. This allows TypeScript to
 * detect the type of a model based on the __typename property. This is
 * indispensible in situations where your desired type is not importable;
 * which is the case for GraphQL implementing types.
 * @param model
 * @param typename
 * @returns assertion of the model's type
 */
export function guardTypename<
  T extends { __typename?: string },
  N extends string,
>(
  model: T,
  typename: N
): model is T & {
  __typename: N;
} {
  return model.__typename === typename;
}

export type UserRoleSelection = {
  [key in AccountRole]: boolean;
};

export interface Redirect {
  redirect: {
    destination: string;
    permanent: boolean;
  };
}

export function hasRedirect<T>(
  obj: Redirect | ApolloQueryResult<T>
): obj is Redirect {
  return "redirect" in obj;
}
